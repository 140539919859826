<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" />
    <CRow>
      <CCol lg="12"> 
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-money" /> Purchase Survey
              <div class="card-header-actions">
                <small class="text-muted">Total: {{ surveys.length }}</small>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :items="surveys"
              :fields="surveyFields"
              :hover="hover"
              column-filter
              items-per-page-select
              :items-per-page="20"
              sorter
              pagination >
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-calculator" /><strong> Summary </strong>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">Total</dt>
              <dd class="col-sm-9">{{ total }}</dd>
            </dl>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Surveys",
  components: {
    Loading,
  },
  data() {
    return {
      surveys: [],
      surveyFields: [
        { key: "heardFrom", label: "Product", filter: false, sorter: true },
        { key: "creator", label: "Creator", filter: false, sorter: true },
        { key: "qty", label: "Quantity", filter: false, sorter: true },
      ],
      hover: true,
      isLoading: false,
    };
  },
  computed: {
      total: function(){
      return this.surveys.reduce(function(sum, item){
        return sum + item.qty; 
      },0);
    }
  },
  methods: {
    getSurveys() {
      this.isLoading = true;
      this.$http
        .get(this.$config.gmapi + "/Report/Surveys", {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.surveys = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.isLoading = false;
        });
    }
  },
  created() {
    this.getSurveys();
  },
};
</script>

<style scoped></style>
